import "./styles/main.css";

new Typewriter("#typewriter", {
  strings: [
    "Content Management",
    "Translation",
    "Media Communication",
    "Software Localization",
  ],
  autoStart: true,
  loop: true,
});
